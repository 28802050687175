<template>
  <div class="out">
     <div class="header">
          <div class="logo Title">
            <div class="Chinese"><img src="../assets/img/return_index.png" class="return1" alt=""
            @click="$router.back()" /> {{$t('main.report')}}</div>
          </div>
          <div class="right-icon">
            <img src="../assets/img/logo-icon.png" alt="">
            <div class="cc-wc">
                {{$t('main.title')}}
            </div>
          </div>
     </div>
     <div class="body">
       <div class="item">
         <div class="title">{{$t('main.reportRes')}} <img src="../assets/img/bitian-logo.png" alt=""></div>
        <div class="goods">
            <div class="good-item" @click="reportItem(item)" :style="isShowReport == item.id ? 'border:0.01rem solid #9A2A25;' : ''" v-for="(item) in list" :key="item.id">
                <img class="good-icon" v-if="isShowReport == item.id" src="../assets/img/report-focus.png" alt="">
                <div class="good-icon" v-if="isShowReport != item.id"></div>
                <div class="good-text">{{item.content}}</div>
            </div>
        </div>
       </div>
       <div class="item">
         <div class="title">{{$t('main.useafi')}} <img src="../assets/img/bitian-logo.png" alt=""></div>
                <div class="sc-btn">

                    <div class="sc-btn-item" @click="upLoadImg">
                        <div v-if="!ds_data.pic">+</div>
                        <img v-else :src="showPic.url" alt="" />
                        <div v-show="showPic.status == 'loading'" class="loading_i">
                            <van-loading type="spinner" color="#fff" size="0.24rem"
                                vertical>{{$t('main.uploading')}}</van-loading>
                        </div>
                    </div>
                </div>
                <textarea class="textarea" maxlength="200" :placeholder="$t('main.pbdescbrepo')" v-model="content" name="" id="" cols="30" rows="10"></textarea>
       </div>
     </div>
     <div class="tijiao-wrap">
        <div class="tijiao" @click="tijiao">{{$t('main.submit')}}</div>
     </div>
     <privacy ref="privacy"></privacy>
  </div>
</template>

<script>
import privacy from "../components/privacy.vue"
export default {
     components: {
    privacy
  },
    data(){
        return{
            list:[],
            isShowReport:-1,
            ds_data:{
                pic:""
            },
            showPic:{
                url:"",
                status:""
            },
            content:"",
            placeholder:"请输入举报内容相关说明，最多200个字符",
        }
    },
    async mounted(){
        
        this.axios.post("/accuse/getAccuseType")
        .then((res)=>{
            if(this.$i18n.locale == 'en'){
                let list = res.data.data.list
                for(let i = 0; i < list.length; i++){
                    if(list[i].content_intl){
                        res.data.data.list[i].content = res.data.data.list[i].content_intl
                    }
                }
            }
            this.list = res.data.data.list ?  res.data.data.list : []
        })
        let token = localStorage.getItem('user_token')
         if(this.$route.query.code && !token){
            let querys= ''
            if(this.$route.query.phone){
                let phone =this.getQueryString('phone')
                let nickName = this.getQueryString('phone_code')
                querys = `&phone=${phone}&phone_code=${decodeURIComponent(nickName)}`
            }
            await this.axios.get('/auth/nt?code='+this.$route.query.code+querys).then((res)=>{
                if(res.data.code == -1 && res.data.msg == '请填写手机号'){
                    
                    this.$refs.privacy.openPopup()
                    return
                }
                window.localStorage.setItem('user_token',res.data.data.token)
            })
        }
        let url = location.protocol + '//' + location.host
        this.axios.post("/auth/wechatConfig", {
            url: url + this.$store.state.jssdkUrl,
            jsApiList: ["chooseImage", "getLocalImgData"],
        })
        .then((res) => {

            let json = JSON.parse(res.data.data.data);

            wx.config({
                debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
                appId: json.appId, // 必填，公众号的唯一标识
                timestamp: json.timestamp, // 必填，生成签名的时间戳
                nonceStr: json.nonceStr, // 必填，生成签名的随机串
                signature: json.signature, // 必填，签名
                jsApiList: json.jsApiList, // 必填，需要使用的 JS 接口列表
            })

        })
        
    },
    methods:{
        reportItem(item){
            this.isShowReport = item.id
        },
        getQueryString(name) {
			var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
			var r = window.location.search.substr(1).match(reg);
			if (r != null) return decodeURIComponent(r[2]);
			return null;
		},
        upLoadImg() {
			let _this = this

			wx.chooseImage({
				count: 1, // 默认9
				sizeType: ["compressed"], // 可以指定是原图还是压缩图，默认二者都有
				sourceType: ["album"], // 可以指定来源是相册还是相机，默认二者都有
				success: (res) => {

					var localIds = res.localIds; // 返回选定照片的本地 ID 列表，localId可以作为 img 标签的 src 属性显示图片
					this.showPic.status = 'loading'
					this.showPic.url = 'http://t.6a.com/87/01193881f683a494.png'
					wx.getLocalImgData({
						localId: localIds[0], // 图片的localID
						success: (res) => {
							var localData = res.localData; // localData是图片的base64数据，可以用 img 标签显示


							var canvas = document.createElement('canvas')
							var ctx = canvas.getContext('2d')
							var img = new Image();
							if (localData.indexOf(',') == -1) {
								localData = 'data:image/jpg;base64,' + localData
							}
							this.showPic.url = localData
							img.onload = () => {
								let image1
								if (img.width > 1024 || img.height > 1024) {
									if (img.width > img.height) {
										let wh = img.width / img.height
										canvas.width = 1024
										canvas.height = 1024 / wh
										ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
										let piccc = canvas.toDataURL("image/png")
										image1 = this.dataURLtoBlob(piccc);
									} else {
										let hw = img.height / img.width
										canvas.width = 1024 / hw
										canvas.height = 1024
										ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
										let piccc = canvas.toDataURL("image/png")
										image1 = this.dataURLtoBlob(piccc);
									}
								} else {
									image1 = this.dataURLtoBlob(localData);
								}
                                
								let param = new FormData();
								param.append("file", image1, 'test.png', {
									headers: {
										"Content-Type": "multipart/form-data",
									},
								});

								this.axios.post("/common/uploadImg", param).then((res) => {
									if (res.data.code == 0) {
										_this.ds_data.pic = res.data.data.url
										this.showPic.status = 'done'

									} else {

										weui.alert('图片太大上传失败',{buttons:[{label:this.$t('main.confIrm')}]})
									}


								});

							}
							img.onerror = () => {
								let image1 = this.dataURLtoBlob(localData);
								let param = new FormData();
								param.append("file", image1, 'test.png', {
									headers: {
										"Content-Type": "multipart/form-data",
									},
								});

								this.axios.post("/common/uploadImg", param).then((res) => {
									_this.ds_data.pic = res.data.data.url
								});
							}
							img.src = localData

						},
					});
				},
			});
		},
		dataURLtoBlob(dataurl) {
			var arr = dataurl.split(',')
			// console.log(arr)
			var mime = 'image/png'

			var bstr = arr[1] ? atob(arr[1]) : atob(arr[0])
			var n = bstr.length
			var u8arr = new Uint8Array(n);
			while (n--) {
				u8arr[n] = bstr.charCodeAt(n);
			}
			return new Blob([u8arr], {
				type: mime
			})
		},
        tijiao(){
            if(this.isShowReport == -1 || !this.ds_data.pic || !this.content){
                return weui.alert(this.$t('main.pctibsub'),{buttons:[{label:this.$t('main.confIrm')}]})
            }
            let reportList 
            if(this.$route.query.accuse_pattern == 1){
                reportList = {
                    info:this.content,
                    img:this.ds_data.pic,
                    type_id:this.isShowReport,
                    artist_id:this.$route.params.id,
                    accuse_pattern:this.$route.query.accuse_pattern
                }
            }else{
                reportList = {
                    info:this.content,
                    img:this.ds_data.pic,
                    type_id:this.isShowReport,
                    goods_id:this.$route.params.id,
                    accuse_pattern:this.$route.query.accuse_pattern
                }
            }
            
            this.axios.post("/accuse/accuseLog",reportList)
            .then((res)=>{
                if(res.data.code == -1){
                    weui.alert(res.data.msg,{buttons:[{label:this.$t('main.confIrm')}]})
                    return
                }
                weui.alert(this.$t('main.rspvp'),{buttons:[{label:this.$t('main.confIrm')}]})
            })
        }
    }
}
</script>

<style scoped>
.out {
  box-sizing: border-box;
  min-height: 100vh;
  /* height: 100vh; */
  overflow-y: auto;
  box-sizing: border-box;
  background: url(../assets/img/index_bg.png) no-repeat center;
  background-size: 100% 100%;
}
.body {
  padding: 0.15rem;
  
  /* background-attachment: fixed; */
}
.header {
	padding: 0.05rem 0.15rem 0.09rem;
	display: flex;
	justify-content: space-between;
  box-sizing: border-box;
	/* line-height: 0; */
	align-items: center;
	background: #FFFFFF;
}
.Title {
	font-size: 0.16rem;
	position: relative;
}



.Chinese {
	color: #1b6a21;
	/* top: -0.2rem; */
	left: 0;
	position: relative;
	text-align: left;
	font-size: 0.18rem;
}

.cc-wc {
	font-size: 0.1rem;
	color: #e9630a;
}

.right-icon {
	/* width: 1rem; */
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.right-icon img {
	display: block;
	width: 100%;
	height: 100%;
	width: 0.65rem;
}
.body .item .title{
    display: flex;
    align-items: center;
}
.body .item:nth-child(2) .title{
    margin-top: 0.15rem;
}
.body .item .title img{
    width: 0.07rem;
    height: 0.07rem;
    margin-left: 0.05rem;
}
.good-item{
    width: 100%;
    padding: 0.1rem 0;
    background-color: white;
    display: flex;
    align-items: center;
    margin-top: 0.1rem;
    border-radius: 0.05rem;
    box-sizing: border-box;
    border: 0.01rem solid white;
}
.good-item .good-icon{
    width: 0.16rem;
    height: 0.16rem;
    margin: 0 0.1rem;
    border: 0.01rem solid #ccc;
    border-radius: 50%;
}
.good-item .good-text{
    width: 2.88rem;
}
.sc-btn {
	display: flex;
	align-items: flex-end;
}

.sc-btn-item {
	position: relative;
	width: 1rem;
	height: 1rem;
	line-height: 1rem;
	text-align: center;
	border-radius: 0.06rem;
	background: white;
	/* box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3); */
	font-size: 0.25rem;
	margin-left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 0.135rem;
    color: #ccc;
}

.sc-btn-item img {
	width: 100%;
	height: 100%;
	display: block;
	object-fit: contain;
}
.loading_i {
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	color: #1989fa;
	font-size: 0.12rem;
	background: rgba(0, 0, 0, 0.6);
}
:deep(.van-loading__text) {
	font-size: 0.12rem;
	line-height: 0.12rem;
	margin: 0;
	margin-top: 0.1rem;
}
.textarea{
    min-width: 90%;
    max-width: 90%;
    height: 1rem;
    margin-top: 0.2rem;
    border: none;
    padding: 0.1rem;
    font-size: 0.14rem;
}
.textarea::placeholder{
    color: #ccc;
    
}
.tijiao-wrap{
    display: flex;
    justify-content: center;
    margin-bottom: 0.2rem;
}
.tijiao{
    background-color: #1989fa;
    color: white;
    width: 60%;
    height: 0.5rem;
    border-radius: 0.05rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>